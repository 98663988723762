<template>
  <div class="container page">
    <van-nav-bar :title="$t('bind_card_title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{ $t('enter_card_info') }}</div>
      <van-cell-group>
        <van-field
            v-model="name"
            :disabled="userInfo.name !== ''"
            :label="$t('name')"
            :placeholder="$t('enter_real_name')"
        />
        <van-field v-model="bank" :label="$t('bank_name')" readonly :placeholder="$t('select_bank')" @click="showSelectBanks()" />
        <van-field v-model="bankid" :label="$t('bank_card_number')" type="digit" :placeholder="$t('enter_real_card_number')" />
      </van-cell-group>
      <p>{{ $t('safety_notice') }}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{ $t('confirm_bind_card') }}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }">
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
          :cancel-button-text="$t('cancel')"
          :confirm-button-text="$t('confirm')"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      bank:"",
      name:""
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.userInfo.bankid){
        this.$toast(this.$t('enter_real_card_number'));
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast(this.$t('select_bank'));
        return false;
      }
      if (!this.name) {
        this.$toast.fail(this.$t('enter_name_warning'));
        return false;
      }
      this.$http({
        method: 'get',
        data: { name: this.name },
        url: 'user_set_name',
      }).then(res => {
        if (res.code === 200) {
          this.$http({
            method: 'post',
            data:{bankid:this.bankid,bank:this.bank},
            url: 'user_set_bank'
          }).then(res=>{
            if(res.code === 200){
              this.$router.push({path:'/Mine'})
              this.$toast(res.msg);
            }else if(res.code ===401){
              this.$toast(res.msg);
            }
          })
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
